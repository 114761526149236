import React from "react";
import { Routes, Route } from "react-router-dom";
import Index from "./screens";

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route key={1} path="/" element={<Index />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
