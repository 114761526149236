import React from "react";
import HomeScreen from "../assets/images/home-screen.png";

const Index = () => {
  return (
    <div>
      <div className="container">
        <img src={HomeScreen} alt="" />
      </div>
    </div>
  );
};

export default Index;
